html {
  font-size: 16px;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}
a {
  color: #1976d2;
}
p {
  margin: 0;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.rdw-option-wrapper {
  border: 0;
}
.rdw-dropdown-selectedtext {
  color: inherit;
}

.new-editor-input-container .rdw-editor-toolbar {
  border: 0;
}
.new-editor-input-container .rdw-editor-main {
  border: 0;
  border-top: 1px solid #cccccc;
  margin-left: -1px;
  margin-right: -1px;
}
